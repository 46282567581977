import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Button,
} from "reactstrap"

import SEO from "../components/seo"

import LineAtLogoImage from "../components/line_at_logo_image"
import MailImage from "../components/mail_image"

import { sendContactInfo } from "../api"

import "./contact.scss"

class Contact extends React.Component {
  state = {
    showView: "form",
    name: "",
    telOrEmail: "",
    formNameError: "",
    formTelOrEmailError: "",
    submittingContact: false,
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    if (name === "name") {
      this.setState({
        formNameError: "",
      })
    } else if (name === "telOrEmail") {
      this.setState({
        formTelOrEmailError: "",
      })
    }
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = async event => {
    event.preventDefault()
    this.setState({
      submittingContact: true,
    })
    const name = this.state.name.trim()
    const telOrEmail = this.state.telOrEmail.trim()
    if (!name) {
      this.setState({
        formNameError: "กรุณาระบุชื่อ",
      })
    }
    if (!telOrEmail) {
      this.setState({
        formTelOrEmailError: "กรุณาระบุเบอร์โทรศัพท์ หรืออีเมล",
      })
    }
    if (name && telOrEmail) {
      try {
        await sendContactInfo({ name, telOrEmail })
        this.setState({
          // name: "",
          // telOrEmail: "",
          showView: "formSendSuccess",
        })
      } catch (error) {
        alert("Error sending contact info. Please try again later.")
      }
    }
    this.setState({
      submittingContact: false,
    })
  }

  render() {
    const {
      pageContext: { locale },
    } = this.props

    return (
      <>
        <SEO title="Contact Us" lang={locale} />
        <div className="Contact">
          <Container>
            <Row>
              <Col sm={7}>
                <div className="ContactSlogan">
                  <div className="ContactSlogan-First">ปฏิวัติ</div>
                  <div className="ContactSlogan-Second">
                    การ
                    <span className="ContactSlogan-PrimaryColor">
                      <span className="ContactSlogan-English">&#8220;</span>
                      เทรนภาษา
                      <span className="ContactSlogan-English">&#8221;</span>
                    </span>
                  </div>
                  <div className="ContactSlogan-Second">
                    ใน
                    <span className="ContactSlogan-PrimaryColor">
                      <span className="ContactSlogan-English">&#8220;</span>
                      องค์กรของคุณ
                      <span className="ContactSlogan-English">&#8221;</span>
                    </span>
                  </div>
                  <div className="ContactSlogan-Third">
                    ด้วย{" "}
                    <span className="ContactSlogan-English">
                      JADOH Learning
                    </span>
                  </div>
                  <div className="Separator" />
                  <div className="ContactSlogan-Third ContactSlogan-PrimaryColor">
                    เทรนได้ 4 ภาษา: อังกฤษ ไทย จีนกลาง ญี่ปุ่น
                  </div>
                  <div className="ContactSlogan-Forth">
                    <span className="ContactSlogan-English">FUN</span> :
                    จนพนักงานคุณหยิบขึ้นมาเล่น (เรียน) เอง
                  </div>
                  <div className="ContactSlogan-Forth">
                    <span className="ContactSlogan-English">PRACTICAL</span> :
                    ด้วย{" "}
                    <span className="ContactSlogan-English">custom lesson</span>{" "}
                    ที่ตรงกับหน้างานของคุณ
                  </div>
                </div>
                <Row className="ContactLinkGroup">
                  <Col sm={4}>
                    <a href="https://jadoh.co" className="ContactLink">
                      jadoh.co
                    </a>
                  </Col>
                  <Col sm={4}>
                    <a
                      href="https://line.me/ti/p/%40jadohlearning"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ContactLink"
                    >
                      <div style={{ width: "30px" }}>
                        <LineAtLogoImage />
                      </div>
                      <div className="ContactLinkText">@jadohlearning</div>
                    </a>
                  </Col>
                  <Col sm={4}>
                    <a
                      href="mailto:info@jadoh.co"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ContactLink"
                    >
                      <div style={{ width: "30px" }}>
                        <MailImage />
                      </div>
                      <div className="ContactLinkText">info@jadoh.co</div>
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col sm={5}>
                <div className="ContactFormContainer">
                  <Card className="ContactFormCard">
                    {this.state.showView === "form" && (
                      <>
                        <CardHeader className="ContactFormCard-Header">
                          <div>เริ่มก้าวแรกสู่การปฏิวัติแบบเบา ๆ</div>
                          <div>เพียงแค่ฝากเบอร์คุณเอาไว้</div>
                        </CardHeader>
                        <CardBody>
                          <Form
                            className="ContactForm"
                            onSubmit={this.handleSubmit}
                          >
                            <FormGroup>
                              <Input
                                type="text"
                                name="name"
                                id="name"
                                placeholder="ชื่อ *"
                                autoComplete="off"
                                value={this.state.name}
                                onChange={this.handleInputChange}
                                invalid={!!this.state.formNameError}
                              />
                              <FormFeedback>
                                {this.state.formNameError}
                              </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                              <Input
                                type="text"
                                name="telOrEmail"
                                id="telOrEmail"
                                placeholder="เบอร์โทรศัพท์ หรืออีเมล์ *"
                                autoComplete="off"
                                value={this.state.telOrEmail}
                                onChange={this.handleInputChange}
                                invalid={!!this.state.formTelOrEmailError}
                              />
                              <FormFeedback>
                                {this.state.formTelOrEmailError}
                              </FormFeedback>
                            </FormGroup>
                            <Button
                              block
                              color="primary"
                              size="lg"
                              type="submit"
                              disabled={this.state.submittingContact}
                            >
                              ฝากข้อมูลติดต่อกลับ
                            </Button>
                          </Form>
                        </CardBody>
                      </>
                    )}
                    {this.state.showView === "formSendSuccess" && (
                      <>
                        <CardHeader className="ContactFormCard-Header">
                          <div>JADOH Learning</div>
                        </CardHeader>
                        <CardBody className="ContactThankYou">
                          {`ขอบคุณ คุณ ${this.state.name} เราจะติดต่อกลับไปโดยเร็ว`}
                        </CardBody>
                      </>
                    )}
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    )
  }
}

export default Contact
